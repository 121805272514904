import React from 'react';
export default function BuyMeACoffee() {
  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h2>Thanks for checking.</h2>
    </div>
  );
}
