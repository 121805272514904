import React from 'react';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import API from '../../api/API';

export default function VerifyEmail() {
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  setLoading(API.verifyEmail(token));

  return loading ? <div>Please wait</div> : <Redirect to='/' />;
}
