// @flow
import SubscribeForm from 'components/subscribe_form/SubscribeForm';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { BiSpaceBar } from 'react-icons/bi';
import { CgSpaceBetweenV } from 'react-icons/cg';


import React from 'react';
import { Link } from 'react-router-dom';
import './Index.css';

export default function Index() {
  return (
    <div
      className='bg-primary'
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 className='text-white'>Hello I&apos;m, </h3>
        <h1 className='text-white'>
          <span className='text-yellow'>&#8201;Dambar.&#8201;</span>
        </h1>
        <h3 className='text-grey'>A  <CgSpaceBetweenV/> <CgSpaceBetweenV/> <CgSpaceBetweenV/> developer.</h3>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FaQuoteLeft
          style={{
            display: 'absolute',
            margin: '0rem 1rem 1rem',
            color: 'white',
          }}
        />
        <h2 className='text-white'>Never give up on your dream</h2>
        <FaQuoteRight
          style={{
            display: 'absolute',
            margin: '0rem 1rem 1rem',
            color: 'white',
          }}
        />
      </div>

      <Link to='/posts' className='text-white' id='btn-check-post'>
        Check my posts
      </Link>
    </div>
  );
}
