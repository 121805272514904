const axios = require('axios').default;

export default class API {
  _getFuncName = () => {
    return this._getFuncName.caller.name;
  };

  static async verifyEmail(token) {
    try {
      const response = await axios.get('/email/verify');
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      console.debug(`${this._getFuncName()} called`);
    }
  }

  static async getPosts() {
    try {
      const res = await axios.get('/email/verify');
      const parsedRes = res.json();

      console.log(res);
    } catch (error) {
      console.error(error);
    } finally {
      console.debug(`${this._getFuncName()} called`);
    }
  }

  static async getPosts() {}
}
