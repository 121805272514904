import React from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

import $ from 'jquery';
import Popper from 'popper.js';

import './App.css';
import { Switch, Route } from 'react-router-dom';

import About from 'pages/about/About';
import BuyMeACoffee from 'pages/buy_me_a_coffee/BuyMeACofee';
import Posts from 'pages/posts/Posts';
import Projects from 'pages/projects/Projects';
import Contact from 'pages/contact/Contact';
import Cookie from 'pages/cookie/Cookie';
import VerifyEmail from 'pages/verify_email/VerifyEmail';

import Index from 'components/index/Index';
import PostCard from 'components/post_card/PostCard';
import CookieConsent from 'components/cookie_consent/CookieConsent';

export default function App() {
  return (
    <div>
      <Header></Header>
      {/* <BrowserRouter> */}
      <Switch>
        <Route path='/posts'>
          <Posts />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/projects'>
          <Projects />
        </Route>
        <Route path='/buy_me_a_coffee'>
          <BuyMeACoffee />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route path='/email/verify'>
          <VerifyEmail />
        </Route>
        <Route path='/cookie'>
          <Cookie />
        </Route>
        <Route path='/'>
          <Index />
        </Route>
      </Switch>
      {/* </BrowserRouter> */}
      {/* <CookieConsent></CookieConsent> */}
    </div>
  );
}

// const data = {
//   id: 1,
//   title: 'Publishing to Maven Central',
//   shortDescription:
//     'A collection of resources which make it easy to publish your library to Maven Central',
//   description: 'Description',
//   tags: [
//     {
//       id: 1,
//       name: 'android library',
//     },
//     {
//       id: 2,
//       name: 'android',
//     },
//     {
//       id: 3,
//       name: 'java',
//     },
//   ],

//   author: {
//     name: 'Dambar',
//   },
//   readTime: 5,
//   createdAt: '4 Feb 2021',
// };

// export default function App() {
//   return <PostCard key={data.id} post={data} />;
// }
