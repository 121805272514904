import './Posts.css';
import React, { useEffect, useState } from 'react';
import PostCard from 'components/post_card/PostCard';

const Posts = () => {
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/posts');
        const json = response.json();
        json.then((data) => {
          setDataSource(data);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (dataSource && Array.isArray(dataSource) && dataSource.length > 0) {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr ',
          columnGap: '2rem',
          rowGap: '2rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {dataSource.map((data) => (
          <PostCard post={data} key={data.id} />
        ))}
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <h2>No posts yet!</h2> */}
        <h2>Chaethay.</h2>
      </div>
    );
  }
};

export default Posts;

{
  /* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            borderRadius: '5px',
            border: '1px solid #000000',
            padding: '0.5rem 1rem',
          }}
        >
          Previous
        </div>
        <div>Page 1 of 9</div>
        <div
          style={{
            borderRadius: '5px',
            border: '1px solid #000000',
            padding: '0.5rem 1rem',
          }}
        >
          Next
        </div>
      </div> */
}
