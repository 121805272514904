// @flow
import React from 'react';
import './Header.css';

import { Link } from 'react-router-dom';
import { BiCoffeeTogo } from 'react-icons/bi';
import { ImCool } from 'react-icons/im';
import { AiOutlineProject } from 'react-icons/ai';

const Header = () => {
  return (
    <header className='App-header'>
      <Link to='/'>
        <span style={{ color: '#FFF' }}>
          <ImCool></ImCool>
        </span>
      </Link>

      <nav className='links-container'>
        <span className='links'>
          <span className='text-secondary font-family-kiwi'>01.</span>
          <Link to='/posts' className='link'>
            Posts
          </Link>
          <span className='text-secondary font-family-kiwi'>02.</span>
          <Link to='/about' className='link'>
            About
          </Link>
          <span className='text-secondary font-family-kiwi'>03.</span>
          <Link to='/projects' className='link buy'>
            Projects
            <AiOutlineProject />
          </Link>
          {/* <span className='text-secondary font-family-kiwi'>04.</span>
          
          <Link to='/contact' className='link'>
            Contact
          </Link>
          <Link to='/buy_me_a_coffee' className='link buy'>
            Buy me a coffee
            <BiCoffeeTogo />
          </Link> */}
        </span>
      </nav>
    </header>
  );
};

export default Header;
