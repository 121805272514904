/* eslint-disable react/prop-types */
// @flow
import './Contact.css';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import ReactDOM from 'react-dom';
import { Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

const loadReCaptcha = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  // script.src = 'https://www.google.com/recaptcha/api.js';
  script.src =
    'https://www.google.com/recaptcha/api.js?render=6LdA89saAAAAAKWKvN1YPkzurK93D_0StKKFUzTe';
  https: document.body.appendChild(script);
};

// import './styles.css';
// import './styles-custom.css';

const Contact = () => {
  loadReCaptcha();
  return (
    <div
      className='bg-primary'
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <span className='text-white' style={{ fontSize: 'calc(10px + 5vh)' }}>
          Wanna build a project?
        </span>
        <span className='text-white' style={{ fontSize: 'calc(10px + 3vh)' }}>
          Let’s talk.
        </span>
      </div>
      <SignupForm />
    </div>
  );
};

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className='text-input' {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </>
  );
};

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <label className='checkbox'>
        <input {...field} {...props} type='checkbox' />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </>
  );
};

// Styled components ....
const StyledSelect = styled.select`
  color: var(--blue);
`;

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: var(--red-600);
  width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: '❌ ';
    font-size: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
`;

const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const SignupForm = () => {
  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          message: '',
          acceptedTerms: false, // added for our checkbox
          jobType: '', // added for our select
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          lastName: Yup.string()
            .max(20, 'Must be 20 characters or less')
            .required('Required'),
          email: Yup.string()
            .max(40, 'Must be 40 characters or less')
            .email('Invalid email addresss`')
            .required('Required'),
          message: Yup.string()
            .max(150, 'Must be 150 characters or less')
            .required('Required'),
          acceptedTerms: Yup.boolean()
            .required('Required')
            .oneOf([true], 'You must accept the terms and conditions.'),
          jobType: Yup.string()
            // specify the set of valid values for job type
            // @see http://bit.ly/yup-mixed-oneOf
            .oneOf(
              ['designer', 'development', 'product', 'other'],
              'Invalid Job Type'
            )
            .required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          grecaptcha.ready(() => {
            grecaptcha
              .execute('6LdA89saAAAAAKWKvN1YPkzurK93D_0StKKFUzTe', {
                action: 'submit',
              })
              .then(async (token) => {
                let response = await fetch(
                  'https://dambarbahadurpun.herokuapp.com/api/contact',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify({
                      firstname: values.firstName,
                      lastname: values.lastName,
                      message: values.message,
                      jobtype: values.jobType,
                      email: values.email,
                      response: token,
                    }),
                  }
                );
                let json = await response.json();
                let { status } = json;
                if (status !== 'ok') {
                  alert('Something went wrong. Please try again later');
                  return;
                }
                resetForm();
                setSubmitting(false);
                // Add your logic to submit to your backend server here.
              });
          });
          // await new Promise((r) => setTimeout(r, 500));
        }}
      >
        <Form>
          <MyTextInput
            label='First Name'
            name='firstName'
            type='text'
            placeholder='Jane'
          />
          <MyTextInput
            label='Last Name'
            name='lastName'
            type='text'
            placeholder='Doe'
          />
          <MyTextInput
            label='Email Address'
            name='email'
            type='email'
            placeholder='jane@company.com'
          />
          <MyTextInput
            label='Message'
            name='message'
            type='text'
            placeholder='Your message'
          />
          <MySelect label='Job Type' name='jobType'>
            <option value=''>Select a job type</option>
            <option value='designer'>Designer</option>
            <option value='development'>Developer</option>
            <option value='product'>Product Manager</option>
            <option value='other'>Other</option>
          </MySelect>
          <MyCheckbox name='acceptedTerms'>
            I accept the terms and conditions
          </MyCheckbox>

          <button type='submit' data-action='submit'>
            Submit
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default Contact;
