import PropTypes from 'prop-types';
import React from 'react';

const PostCard = ({ post }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4rem 2rem 1rem 2rem',
        }}
      >
        <img
          alt='title'
          style={{ width: '100%', height: '100%', borderRadius: '5px' }}
          src='https://res-1.cloudinary.com/dwihnuu0r/image/upload/q_auto/lysander-yuen-wk833OrQLJE-unsplash.jpg'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
          }}
        >
          {post.tags.map((tag) => (
            <div key={tag} style={{ padding: '0 4px' }}>
              #{tag}
            </div>
          ))}
        </div>

        <h3 className='text-primary' style={{ fontWeight: 'bold' }}>
          {post.title}
        </h3>
        <div>{post.shortDescription}</div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{post.author.name}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>{post.author.name}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              {/* <div>{post.createdAt}</div> */}
              <div>{post.readTime}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readTime: PropTypes.number.isRequired,
    createdAt: PropTypes.shape({
      _seconds: PropTypes.number.isRequired,
      _nanoseconds: PropTypes.number.isRequired,
    }),
    updatedAt: PropTypes.shape({
      _seconds: PropTypes.number.isRequired,
      _nanoseconds: PropTypes.number.isRequired,
    }),
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    tags: PropTypes.array,
  }),
};
export default PostCard;
