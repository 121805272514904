import './Projects.css';
import React, { useEffect, useState } from 'react';
import ProjectCard from 'components/project_card/ProjectCard';

const Projects = () => {
  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h2>You need to search.</h2>
    </div>
  );
  // return <ProjectCard />;
};

export default Projects;
