import React from 'react';
import { AiFillStar } from 'react-icons/ai';
export default function About() {
  return (
    <div>
      <div>
        <h2>Simple tech stack.</h2>
        <div>
          <ol style={{ listStyleType: 'none'}}>
            <li ><strong><AiFillStar/> Core</strong></li>
            <ul><li>React</li></ul>
            <li><strong><AiFillStar /> Deployment</strong></li>
            <caption>Just push to git master branch to deploy to your cloud provider.</caption>
            <ul><li>git push origin master</li></ul>
          </ol>
        </div>
      </div>
    </div>
  );
  // return <h2>About</h2>;
}
